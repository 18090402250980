import * as React from "react";
import "./devis.css";
import Layout from "../layouts/layout";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";



const DevisPage = () => {


    return (
        <>
            <Layout>

                <Helmet htmlAttributes={{
                    lang: 'fr-FR',
                }}>
                    <link rel="icon" type="image/x-icon" href={favicon} />
                    <title>Devis</title>
                    <meta name="robots" content="index, follow" />
                    <link data-react-helmet="true" rel="canonical" href="https://www.medica-tour.fr/devis/" />
                    <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>
    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
            </Helmet>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>

                <div className="container-fluid service pt-5 pb-5">
                    <div className="container intern-page-style">
                        <h1 className="service-title">
                            Demande de devis
                        </h1>

                    </div>
                </div>

                <div className="container-fluid">

                    <div class="container pt-5 mb-5">

                        <div class="jumbotron text-center">
                            <h2 class="display-3">Merci pour votre confiance !</h2>

                            <strong>votre demande a été envoyé avec succès</strong>
                            <hr />

                            <Link to="/" class="btn btn-primary">ACCUEIL</Link>

                        </div>

                    </div>
                </div>

            </Layout>
        </>
    )
}

export default DevisPage
